$(document).ready(function () {
    $('.dropdown-toggle').dropdown();
    /*******************************************************************************
     SLICK
     *******************************************************************************/
    /*******************************************************************************
     NAVBAR
     *******************************************************************************/

    $(".navbar-toggle").on("click", function (e) {
        $(this).toggleClass("open");
        $(".navbar-collapse").toggleClass("in");
    });
    /*
    $(".submenu .item").mouseover(function () {
        $(this).addClass("show");
    }).mouseleave(function () {
        $(this).removeClass("show");
    });
    */
    // DELAY ADDED
    var timer;
    $(".submenu .item").mouseenter(function () {
    var that = this;
    timer = setTimeout(function(){
    $(that).addClass("show");
    }, 250);
    }).mouseleave(function () {
        $(this).removeClass("show");
        +        clearTimeout(timer);
    });

    $(".nav-link.search").on("click", function (e) {
        $("#search").toggleClass("open");
    });
    $("#search .closebtn").on("click", function (e) {
        $("#search").toggleClass("open");
    });

    /*******************************************************************************
     WOW
     *******************************************************************************/

    new WOW().init();

    /*******************************************************************************
     SVG TO HTML
     *******************************************************************************/

    jQuery('img.svg').each(function () {
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function (data) {
            var $svg = jQuery(data).find('svg');
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }
            $svg = $svg.removeAttr('xmlns:a');
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }
            $img.replaceWith($svg);
        }, 'xml');
    });

    /*******************************************************************************
     SWIPER
     *******************************************************************************/

    var galleryTopProduct = new Swiper('.gallery-top', {
        spaceBetween: 10,
        zoom: true,
        pagination: '.swiper-pagination-gallery-product',
        zoomMax: 1.5,
        onSlideChangeEnd: function (swiper) {
            var activeIndex = swiper.activeIndex;
            $(galleryThumbsProduct.slides).removeClass('is-selected');
            $(galleryThumbsProduct.slides).eq(activeIndex).addClass('is-selected');
            galleryThumbsProduct.slideTo(activeIndex, 150, false);
        }
    });
    var galleryThumbsProduct = new Swiper('.gallery-thumbs', {
        spaceBetween: 10,
        freeMode: true,
        centeredSlides: false,
        slidesPerView: 'auto',
        touchRatio: 0.2,

        direction: 'vertical',
        onClick: function (swiper, event) {
            var clicked = swiper.clickedIndex;
            swiper.activeIndex = clicked; //don't need this
            swiper.updateClasses(); //don't need this
            $(swiper.slides).removeClass('is-selected');
            $(swiper.clickedSlide).addClass('is-selected');
            galleryTopProduct.slideTo(clicked, 150, false);
        }
    });


    $(".slider-hero .swiper-container").each(function (index, element) {
        var $this = $(this);
        var selector = "#slider-hero-" + index;
        if ($(".slider-hero .swiper-container .swiper-slide").length == 1) {
            $('.slider-hero .swiper-pagination').addClass("disabled");
        }
        $this.attr('id', "slider-hero-" + index);
        $this.find(".swiper-button-prev").addClass("btn-prev-hero-" + index);
        $this.find(".swiper-button-next").addClass("btn-next-hero-" + index);
        $this.find(".swiper-pagination").addClass("swiper-pagination-hero-" + index);
        var swiperHero = new Swiper(selector, {
            paginationClickable: true,
            preventClicks: false,
            keyboardControl: true,
            resistanceRatio: 0,
            preventClicksPropagation: false,
            speed: 500,
            parallax: true,
            pagination: ".swiper-pagination-hero-" + index,
            nextButton: ".btn-next-hero-" + index,
            prevButton: ".btn-prev-hero-" + index,
            onTransitionEnd: function (swiper) {
                var i = swiper.activeIndex;
                if (typeof(this.prev_slider_proc) !== 'undefined' && this.prev_slider_proc == i) {
                    return;
                }
                this.prev_slider_proc = i;

                var $swiper = $(swiper.container);
                $('iframe.video-frame', $swiper).attr('src', 'about:blank').hide();
                $('.swiper-slide', $swiper).removeClass('video-playing');

                var $slide = $(swiper.slides[i]);
                if (!$slide.hasClass('slide-video')) return;
            }
        });

        var click = 1;
        $this.on('mousedown', '.swiper-slide', function () {
            click = 1;
        });
        $this.on('mousemove', '.swiper-slide', function () {
            click = 0;
        });
        $this.on('mouseup', '.swiper-slide', function () {
            if (!click) return;
            var $slide = $(this);
            var code = $slide.data('code');
            var params = $slide.data('params');
            if (!$slide.hasClass('video-playing')) {
                $slide.addClass('video-playing');
                $('iframe.video-frame', $slide).attr('src', '//www.youtube.com/embed/' + code + '?' + params).show();
            } else {
                $slide.removeClass('video-playing');
                $('iframe.video-frame', $slide).attr('src', 'about:blank').hide();
            }

        });

    });

    $(".slider-multimedia .swiper-container").each(function (index, element) {
        var $this = $(this);
        $this.attr('id', "slider-multimedia-" + index);
        $this.find(".swiper-button-prev").addClass("btn-prev-multimedia-" + index);
        $this.find(".swiper-button-next").addClass("btn-next-multimedia-" + index);
        $this.find(".swiper-pagination").addClass("swiper-pagination-multimedia-" + index);

        var swiperMultimedia = new Swiper("#slider-multimedia-" + index, {
            paginationClickable: true,
            preventClicks: false,
            keyboardControl: true,
            resistanceRatio: 0,
            preventClicksPropagation: false,
            speed: 500,
            autoplay: 3500,
            parallax: true,
            pagination: ".swiper-pagination-multimedia-" + index,
            nextButton: ".btn-next-multimedia-" + index,
            prevButton: ".btn-prev-multimedia-" + index,
            onTransitionEnd: function (swiper) {
                var i = swiper.activeIndex;
                if (typeof(this.prev_slider_proc) !== 'undefined' && this.prev_slider_proc == i) {
                    return;
                }
                this.prev_slider_proc = i;

                var $swiper = $(swiper.container);
                $('iframe.video-frame', $swiper).attr('src', 'about:blank').hide();
                $('.swiper-slide', $swiper).removeClass('video-playing')

                var $slide = $(swiper.slides[i]);
                if (!$slide.hasClass('slide-video')) return;
            }
        });

        var click = 1;
        $this.on('mousedown', '.swiper-slide', function () {
            click = 1;
        });
        $this.on('mousemove', '.swiper-slide', function () {
            click = 0;
        });
        $this.on('mouseup', '.swiper-slide', function () {
            if (!click) return;
            var $slide = $(this);
            var code = $slide.data('code');
            var params = $slide.data('params');
            if (!$slide.hasClass('video-playing')) {
                $slide.addClass('video-playing');
                $('iframe.video-frame', $slide).attr('src', '//www.youtube.com/embed/' + code + '?' + params).show();
            } else {
                $slide.removeClass('video-playing');
                $('iframe.video-frame', $slide).attr('src', 'about:blank').hide();
            }

        });
    });


    $(".carousel .swiper-container").each(function (index, element) {
        var $this = $(this);
        $this.attr('id', "slider-carousel-" + index);
        $this.parentsUntil(".carousel").find(".swiper-button-prev").addClass("btn-prev-carousel-" + index);
        $this.parentsUntil(".carousel").find(".swiper-button-next").addClass("btn-next-carousel-" + index);
        $this.parentsUntil(".carousel").find(".swiper-pagination").addClass("swiper-pagination-carousel-" + index);
        var swiper = new Swiper("#slider-carousel-" + index, {
            paginationClickable: true,
            preventClicks: false,
            resistanceRatio: 0,
            loopedSlides: true,
            slidesPerView: 4,
            spaceBetween: 4,
            observer: true,
            preventClicksPropagation: false,
            speed: 500,
            breakpoints: {
                1920: {
                    slidesPerView: 4,
                    spaceBetween: 4

                },
                1340: {
                    slidesPerView: 3,
                    spaceBetween: 3

                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 2
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 1
                }
            },
            pagination: ".swiper-pagination-carousel-" + index,
            nextButton: ".btn-next-carousel-" + index,
            prevButton: ".btn-prev-carousel-" + index
        });
        var domElement = $this.get(0);
        domElement.swiperInstance = swiper;
    });


    var swiperItems = new Swiper('#items', {
        paginationClickable: true,
        preventClicks: false,
        resistanceRatio: 0,
        loopedSlides: true,
        slidesPerView: 6,
        spaceBetween: 6,
        pagination: '.swiper-pagination-items',
        nextButton: '.swiper-button-next-slider-items',
        prevButton: '.swiper-button-prev-slider-items',
        preventClicksPropagation: false,
        speed: 500,
        breakpoints: {
            1920: {
                slidesPerView: 6,
                spaceBetween: 6
            },
            768: {
                slidesPerView: 5,
                spaceBetween: 5
            },
            640: {
                slidesPerView: 3,
                spaceBetween: 3
            },
            400: {
                slidesPerView: 2,
                spaceBetween: 2
            }
        }
    });


    var swiperClients = new Swiper('#swiperClients', {
        paginationClickable: true,
        preventClicks: false,
        resistanceRatio: 0,
        loopedSlides: true,
        slidesPerColumn: 3,
        slidesPerView: 6,
        pagination: '.swiper-pagination-clients',
        preventClicksPropagation: false,
        speed: 500,
        breakpoints: {
            1920: {
                slidesPerColumn: 3,
                slidesPerView: 6
            },
            1280: {
                slidesPerColumn: 3,
                slidesPerView: 5,
                slidesPerGroup: 5
            },
            1024: {
                slidesPerColumn: 3,
                slidesPerView: 4,
                slidesPerGroup: 4
            },
            768: {
                slidesPerColumn: 4,
                slidesPerView: 4,
                slidesPerGroup: 4
            },
            640: {
                slidesPerColumn: 4,
                slidesPerView: 3,
                slidesPerGroup: 3
            }
        }
    });

    if ($(".gallery-product-wrapper .swiper-container.gallery-top .swiper-wrapper .swiper-slide").length === 1) {
        $('.swiper-pagination').hide();
        $('.swiper-button-prev').hide();
        $('.swiper-button-next').hide();
    } else {
        $('.swiper-pagination').show();
        $('.swiper-button-prev').show();
        $('.swiper-button-next').show();
    }


    /*******************************************************************************
     MEGAMENU
     *******************************************************************************/

    $(".megamenu-special").each(function () {
        $mm = $(this);
        var av_active_filter = $(".filters ul li a.menu-active", $mm);
        if (!av_active_filter.length) {
            var first = $(".filters ul li:first-child > a", $mm);
            first.each(function () {
                $(this).addClass('active');
                $("#" + $(this).attr("data-related")).addClass('active');
            });
        }
        $(".megamenu .wrapper-content-filter", $mm).addClass('active');
        $(".megamenu .content", $mm).addClass('active');
        $("#" + av_active_filter.attr("data-related")).addClass("active");
        $('.menu-active', $mm).addClass("active");

        $('.filters ul li a', $mm).mouseover(function (e) {
            if (!$(this).hasClass('menu-active')) {
                $(".filters ul li a", $mm).removeClass("active");
            }

            $(this).addClass("active");
            var id = $(this).attr('data-related');
            $(".content-filter", $mm).each(function () {
                $(this).removeClass("active");
                if ($(this).attr('id') == id) {
                    $(this).addClass("active");
                }
            });
        });

        $('.megamenu', $mm).mouseleave(function (e) {
            $(".filters ul li a", $mm).each(function () {
                $(this).removeClass("active");
                $("#" + $(this).attr("data-related")).removeClass("active");
            });
            if (av_active_filter.length < 1) {
                var first = $(".filters ul li:first-child a:first-child", $mm);
                first.addClass('active');
                $("#" + first.attr("data-related")).addClass('active');
            } else {
                av_active_filter.addClass("active");
                $("#" + av_active_filter.attr("data-related")).addClass("active");
            }
        });
    });


    $(".megamenu-special").each(function () {
        var $mm = $(this);
        var active_filter = $('.filters ul li a.menu-active', $mm);
        if (!active_filter.length) {
            var $first = $('.filters ul li:first-child > a', $mm);
            $first.addClass('active');
            active_filter = $first;
        }
        $('.megamenu .wrapper-content-filter', $mm).addClass('active');
        $('.content-filter', $mm).hide();
        $('#' + active_filter.attr('data-related')).show();
        $('.menu-active', $mm).addClass('active');

        $('.filters li a', $mm).mouseover(function (e) {
            var $this = $(this);
            var id = $this.attr('data-related');
            $('.filters li a', $mm).removeClass('active');
            $this.addClass('active');
            $('.content-filter', $mm).each(function () {
                var $this = $(this);
                if ($this.attr('id') == id) {
                    $this.show();
                } else {
                    $this.hide();
                }
            });
        });

        $('.megamenu', $mm).mouseleave(function (e) {
            $('.filters ul li a', $mm).removeClass('active');
            $('.filters .content-filter', $mm).removeClass('active');
            active_filter.addClass('active');
            $('#' + active_filter.attr('data-related')).addClass('active');
        });
    });

    // Highlight anchor hash on MegaMenu
    function checkHash() {
        $('.navbar .hashed').removeClass('active hashed');
        var module = window.location.pathname.split('/').pop();
        var hash = window.location.hash ? window.location.hash.substr(1) : false;
        if (!hash) return;
        $('.navbar .menu-active .subcat-' + module + ' .item-' + hash).addClass('active hashed');
    }

    $(window).on('hashchange', function () {
        checkHash();
    });
    checkHash();
});

/*******************************************************************************
 MATCH MEDIA
 *******************************************************************************/

$(function () {
    if (matchMedia) {
        var mq = window.matchMedia("(min-width: 1025px)");
        mq.addListener(WidthChange);
        WidthChange(mq);
    }

    function WidthChange(mq) {
        if (mq.matches) {
            $(".bg_images_dm").each(function () {
                var attr = $(this).attr('data-image-src');
                if (typeof attr !== typeof undefined && attr !== false) {
                    $(this).css('background-image', 'url(' + attr + ')');
                }
            });
        } else {
            $(".bg_images_dm").each(function () {
                var attr = $(this).attr('data-image-src-sm');
                if (typeof attr !== typeof undefined && attr !== false) {
                    $(this).css('background-image', 'url(' + attr + ')');
                }
            });
        }
    }
});


/*******************************************************************************
 COMPRUEBA TU AHORRO
 *******************************************************************************/

$(function () {
    $("#calcular").on("click", function (e) {
        e.preventDefault();

        $(".resultado").addClass("open");
        doCalc($("#calc_quantity").val());
    });
});


/*
 * Calculadora de Comprueba tu ahorro
 *
 * 2009/09/03
 * Cristian Acuña
 * cacuna@4sale.cl
 *
 * Depende de jQuery 1.3+
 * http://jquery.com
 *
 * Depende de NumberFormat154.js +
 *
 *
 * modified by Peter Mint - Avatar - Feb 2017
 *
 */


var _supHi = Array(5.19, 5.19, 5.19);
var _empHi = Array(_supHi[0] - (_supHi[0] * 0.28), _supHi[1] - (_supHi[1] * 0.4), _supHi[1] - (_supHi[1] * 0.57));

var _supTo = Array(1.87, 1.87, 1.87);
var _empTo = Array(_supTo[0] - (_supTo[0] * 0.3), _supTo[1] - (_supTo[1] * 0.4), _supTo[1] - (_supTo[1] * 0.36));

function formatThousandsWithRounding(n, dp) {
    var w = n.toFixed(dp), k = w | 0, b = n < 0 ? 1 : 0,
        u = Math.abs(w - k), d = ('' + u.toFixed(dp)).substr(2, dp),
        s = '' + k, i = s.length, r = '';
    while ((i -= 3) > b) {
        r = '.' + s.substr(i, 3) + r;
    }
    return s.substr(0, i + 3) + r + (d ? ',' + d : '');
};

var doCalc = function (valx) {

    if (!valx) {
        valx = 1;
        $("#calc_quantity").val(formatThousandsWithRounding(Number(valx), 0));
    }

    $("#calc_trab").html(formatThousandsWithRounding(Number(valx), 0));


    var _inEmp = valx
    if (isNaN(_inEmp) || _inEmp.length < 1) {

        return false;
    }
    var _totalIns = 0;
    var _totalSup = 0;

    var _resEmpHiIns = ((_inEmp <= 50) ? _empHi[0] * _inEmp : 0) +
        ((_inEmp > 50 && _inEmp <= 100) ? _empHi[1] * _inEmp : 0) +
        ((_inEmp > 100) ? _empHi[2] * _inEmp : 0);
    _totalIns += _resEmpHiIns;
    _resEmpHiIns = formatThousandsWithRounding(Number(_resEmpHiIns), 1);

    var _resEmpHiSup = ((_inEmp <= 50) ? _supHi[0] * _inEmp : 0) +
        ((_inEmp > 50 && _inEmp <= 100) ? _supHi[1] * _inEmp : 0) +
        ((_inEmp > 100) ? _supHi[2] * _inEmp : 0);
    _totalSup += _resEmpHiSup;
    _resEmpHiSup = formatThousandsWithRounding(Number(_resEmpHiSup), 1);


    var _resEmpToIns = ((_inEmp <= 50) ? _empTo[0] * _inEmp : 0) +
        ((_inEmp > 50 && _inEmp <= 100) ? _empTo[1] * _inEmp : 0) +
        ((_inEmp > 100) ? _empTo[2] * _inEmp : 0);
    _totalIns += _resEmpToIns;
    _resEmpToIns = formatThousandsWithRounding(Number(_resEmpToIns), 1);

    var _resEmpToSup = ((_inEmp <= 50) ? _supTo[0] * _inEmp : 0) +
        ((_inEmp > 50 && _inEmp <= 100) ? _supTo[1] * _inEmp : 0) +
        ((_inEmp > 100) ? _supTo[2] * _inEmp : 0);
    _totalSup += _resEmpToSup;
    _resEmpToSup = formatThousandsWithRounding(Number(_resEmpToSup), 1);


    var _diferencia = _totalSup - _totalIns;
    var _diferenciaP = (1 - (_totalIns / _totalSup)) * 100;


    _totalIns = formatThousandsWithRounding(Number(_totalIns), 1);
    _totalSup = formatThousandsWithRounding(Number(_totalSup), 1);


    ahorroConsumo.update(parseInt(_diferenciaP), 10);
    ahorroMetros.update(parseFloat(Number(_diferencia).toFixed(1)));
    var spanMts = "&nbsp;<span>mts</span>";
    $("#td_h_h_e").html(_resEmpHiIns+spanMts);
    $("#td_h_h_r").html(_resEmpHiSup+spanMts);
    $("#td_h_t_e").html(_resEmpToIns+spanMts);
    $("#td_h_t_r").html(_resEmpToSup+spanMts);
    $("#td_m_t_e3").html(_totalIns+spanMts);
    $("#td_m_t_r3").html(_totalSup+spanMts);
    // $("#result_metros").text(_diferencia);
    // $("#result_percent").text(_diferenciaP);


    return true;
}

var ahorroMetros = Circles.create({
    id: 'ahorroMetros',
    radius: 90,
    maxValue: 9999,
    value: 0,
    width: 4,
    text: function (value) {
        return value + '<span>metros diarios</span>';
    },
    wrpClass: 'circles-wrp',
    textClass: 'circles-text',
    colors: ['#9499a1', '#0084c9'],
    duration: 2000
});

var ahorroConsumo = Circles.create({
    id: 'ahorroConsumo',
    radius: 90,
    value: 0,
    maxValue: 100,
    width: 4,
    text: function (value) {
        return value + '% <span>economia</span>';
    },
    wrpClass: 'circles-wrp',
    textClass: 'circles-text',
    colors: ['#9499a1', '#0084c9'],
    duration: 2000
});

/*******************************************************************************
 ANCHOR HREF HELPER
 *******************************************************************************/

function hashChange() {
    var hash = window.location.hash;
    if (!hash || hash == '#' || !$(hash).length) return;
    var $hash = $(hash);
    var $wrapper = $hash.closest('.container-fluid');
    var $elem = ($wrapper.length ? $wrapper : $hash);
    var $body = $("html, body");
    $body.stop().animate({ scrollTop: $elem.offset().top - 175 }, 500, 'swing');
    if ($hash.hasClass('filter-btn')) {
        $hash.click();
    } else if($wrapper.find('a.filter-none').length) {
        $wrapper.find('a.filter-none').click();
    }
}
$(window).load(function(){
    $(window).on('hashchange', function (e) {
        e.preventDefault();
        hashChange();
    });
    hashChange();
});
/*******************************************************************************
 Carousel filter behavior
 *******************************************************************************/

$(function () {
    $(".filters").on('click', '.filter-btn', function (e) {
        e.preventDefault();
        var $this = $(this);
        $this.siblings().removeClass("active");
        $this.addClass("active");

        var id = $this.data('id');
        var slug = $this.data('filter-x');

        var $car = $('.swiper-container.car-' + id);
        if (!slug) {
            $car.find('.item').show();
        } else {
            $car.find('.item').hide();
            $car.find('.item.cat-' + slug).show();
        }

        // Get the swiper instance
        var domElement = $car.get(0);
        var swiper = domElement.swiper;
        swiper.update();
        swiper.slideTo(0, 0);
    });
});

/*******************************************************************************
 SEARCH BUTTON
 *******************************************************************************/
$(document).ready(function() {
    $('input#site_search').change(function() {
        var newurl = '/buscar/' + $("input[name=csrf_token_search]").val() + '/'+ $(this).val();
        $('a#nav-search').attr('href', newurl);
    });
    $('input#site_search-b').change(function() {
        var newurl = '/buscar/' + $("input[name=csrf_token_search]").val() + '/'+ $(this).val();
        $('a#nav-search-b').attr('href', newurl);
    });
});

/*******************************************************************************
 DISTRIBUTOR LINK FUNCIONALITY
 *******************************************************************************/
$(document).ready(function () {
    $('.mapa .distributor a').on('click', function(e) {
        e.preventDefault();
        sessionStorage.setItem('distributor', true);
        var url = $(this).attr('href');
        window.location.assign(url);
    });
});
